import React from 'react'

interface Props extends React.SVGAttributes<SVGSVGElement> {}

function CudoTravelLogo(props: Props) {
  return (
    <svg {...props} viewBox="0 0 120 40">
      <path fillRule="evenodd" d="M87.236 34.07c-2.998 0-4.09-5.645-4.09-9.826 0-4.019.873-9.012 3.87-9.012 2.618 0 3.981 3.96 4.091 9.012.108 4.398-.874 9.825-3.87 9.825zm11.752-9.555c.273 9.175-3.957 15.31-11.917 15.31-7.359 0-11.827-5.376-11.827-14.766 0-9.989 4.959-14.876 11.94-14.876 8.773 0 11.585 7.275 11.804 14.333v-.001zm-59.145-9.153c0-5.655 6.251-5.249 7.943-5.179V29.04c0 1.394-1.519 10.782-11.22 10.785-9.7-.003-11.219-9.39-11.219-10.785V10.183c1.69-.07 7.942-.477 7.942 5.179v12.854c0 1.357.064 5.85 3.278 5.853 3.212-.003 3.276-4.496 3.276-5.853V15.362zM16.959 29.675c1.417-1.562 4.815-1.313 6.522-.676 0 3.853-2.33 10.826-11.1 10.826C4.15 39.825 0 31.671 0 25.073 0 18.475 4.151 10.32 12.38 10.32c8.77 0 11.1 6.973 11.1 10.826-1.706.637-5.104.886-6.52-.675-.942-1.463-2.469-3.313-4.43-3.313-3.564 0-4.627 2.333-4.627 7.914 0 5.581 1.063 7.913 4.626 7.913 1.962 0 3.49-1.85 4.43-3.311zm48.448-24.06c0-5.845 6.344-5.41 7.902-5.367.002.368 0 1.073 0 1.927V31.34c-.033 1.028 0 3.961.156 4.975-.088.425-6.675 3.51-10.821 3.51-9.144 0-13.078-5.865-13.078-14.248 0-8.272 4.254-15.725 11.641-15.725 2.233 0 3.404.657 4.2 1.095V5.614zm0 25.498V15.168c-.532-.33-1.222-.713-2.179-.713-3.562 0-5.76 4.821-5.76 9.973 0 5.149 2.198 9.916 5.654 9.916 2.231 0 2.285-2.027 2.285-3.23z"/>
    </svg>
  )
}

export default CudoTravelLogo
